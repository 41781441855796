






















































































import { Component, Watch } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import { ProjectInterface } from "@/types/project.type";
import ProjectForm from "@/components/project/ProjectForm.vue";
import { SnackbarModule } from "@/store/modules/snackbar-module";
import { DurationHelperMixins } from "@/mixins/duration-helper-mixins";
import { DateConverterMixin } from "@/mixins/date-converter-mixin";
import { mixins } from "vue-class-component";
import { ProjectModule } from "@/store/modules/project-module";
import { UserModule } from "@/store/modules/user-module";
import { RoleMixin } from "@/mixins/role-mixin";
import moment from "moment";
import TableComponent from "@/components/TableComponent.vue";
import Header from "@/components/Header.vue";
import SearchField from "@/components/text-field/SearchField.vue";
import CreateButton from "@/components/buttons/CreateButton.vue";
import Dialog from "@/components/Dialog.vue";
import ConfirmationDialog from "@/components/utils/ConfirmationDialog.vue";
import { DataTableOption } from "@/types/data-table.type";

@Component({
  components: {
    ProjectForm,
    ConfirmationDialog,
    TableComponent,
    Header,
    SearchField,
    CreateButton,
    Dialog,
  },
})
export default class ProjectList extends mixins(DurationHelperMixins, RoleMixin, DateConverterMixin) {
  public $refs!: {
    projectForm: HTMLFormElement;
    deleteDialog: HTMLFormElement;
    dialog: HTMLFormElement;
  };

  private projectDialog: boolean = false;

  private editModeStatus: boolean = false;

  private projectId: number | null | string = 1;

  private projectForm: object = {};

  private descProjects: ProjectInterface[] = [];

  private projectFormDefault: ProjectInterface = {
    id: -1,
    active: true,
    client: -1,
    name: "",
    remark: "",
    contactPersonName: "",
    contactPersonEmail: "",
    billedDuration: 0,
    totalDuration: 0,
    startDate: null,
    projectType: "",
    canEdit: false,
    tags: [],
    autoApproveTimesheet: false,
    alertCount: 0,
    billable: false,
    approvedDuration: 0,
    rejectedDuration: 0,
    pendingDuration: 0,
  };

  private searchProject: string = "";
  private timer?: NodeJS.Timeout;

  @Watch("projectDialog")
  private projectDialogChanged(val: boolean) {
    if (!val) {
      this.closeDialog();
    }
  }

  @Watch("searchProject")
  private searchChange(newVal: string) {
    if (this.timer) {
      clearTimeout(this.timer);
    }

    const vm = this;

    this.timer = setTimeout(() => {
      vm.optionChanged();
    }, 1000);
  }

  @Watch("projects")
  private sortProjects() {
    const lst: ProjectInterface[] = Object.assign([], this.projects);
    this.descProjects = [...lst].reverse();
  }

  private goToProjectDetail(projectId: number): void {
    this.$router.push({
      name: "Project Detail",
      params: { id: projectId.toString() },
    });
  }

  get projects(): ProjectInterface[] {
    return ProjectModule.projects;
  }

  get projectHeaders(): DataTableHeader[] {
    return ProjectModule.projectHeaders;
  }

  get projectCount(): number {
    return ProjectModule.totalProject;
  }

  private async created() {
    this.projectForm = Object.assign({}, this.projectFormDefault);
  }

  private openEditProjectDialog(project: object) {
    this.editModeStatus = true;
    this.projectForm = Object.assign({}, project);
    this.projectDialog = true;
  }

  private formatCreated(time: string) {
    return time == null ? "-" : moment(time).format("YYYY-MM-DD");
  }

  private openProjectDialog() {
    this.editModeStatus = false;
    this.projectDialog = true;
  }

  private closeDialog() {
    this.$refs.dialog.simpleCloseDialog();
    this.projectDialog = false;
    setTimeout(() => {
      this.projectForm = Object.assign({}, this.projectFormDefault);
      this.editModeStatus = false;
      const resetAllValidation = this.$refs.projectForm.$refs.form;
      resetAllValidation.resetValidation();
    }, 300);
    this.optionChanged();
  }

  private optionChanged(newOptions?: DataTableOption) {
    if (!newOptions) {
      newOptions = {
        itemsPerPage: 10,
        page: 1,
        sortBy: ["alertCount"],
        sortDesc: [true],
      };
    }

    if (this.searchProject) {
      newOptions.search = this.searchProject;
    }

    ProjectModule.getProjects(newOptions);
  }

  private openDeleteProjectDialog(id: number) {
    this.projectId = id;
    this.$refs.deleteDialog.openDialog();
  }

  private async deleteProject() {
    try {
      await ProjectModule.deleteProject(this.projectId as number);
    } catch ({ response }) {
      await SnackbarModule.setSnack({
        color: "error",
        message: response.data.detail,
      });
      return;
    }
    await SnackbarModule.setSnack({
      color: "success",
      message: "Delete project successfully!",
    });
  }

  private hasAccess() {
    return UserModule.isAdmin;
  }

  private updateSearch(txt: string) {
    this.searchProject = txt;
  }

  private openCreateProjectDialog() {
    this.$refs.dialog.openDialog();
  }

  private simpleCloseDialog() {
    this.$refs.dialog.simpleCloseDialog();
  }

  private goToRowDetail(data: ProjectInterface) {
    this.$router.push({
      name: "Project Detail",
      params: { id: data.id!.toString() },
    });
  }
}
