import Vue from "vue";
import moment from "moment";
import Component from "vue-class-component";

@Component
export class DateConverterMixin extends Vue {
  public humanizeDate(timestamp: number) {
    return timestamp == null ? "-" : moment(timestamp).format("YYYY-MM-DD");
  }
}
